$(document).ready(function() {
  sortTable();
  arrowScroll();
});


$(window).load(function() {
  // initialize mobile nav
  $(window).width() < 736 ? mobileNav() : '';

  // initialize minimal nav
  $(window).width() >= 1024 && $(window).width() <= 1280 && $('.page-single').length > 0 ? minimalNav() : $('.main-header').removeClass('main-header--minimal');

  // initialize homeslider
  $('.home-slider').length && homeSlider();

  // set height of info-overlay in case of video
  $('.projects-single--video').length && findOutSliderHeight();

  // set height of info texts (office/contact)
  $('.info').length > 0 && $(window).width() > 1280 ? setInfoHeight() : setInfoHeight(false);

  // set project-single images
  $('.flexslider').length > 0 && $(window).width() > 736 ? flexslider() : mobileImages();

  // apply object-fit: contain to flexslider-images
  //$('.flexslider').length > 0 && $(window).width() > 736 &&
  objectFitImages();

  // set info overlay functionality
  toggleInfoOverlay();

  // initialize map
  $('#map').length > 0 ? initMap() : '';

  $('.projects-single--video').length && $(".video-content").fitVids();

});

$(window).resize(function() {
  // initialize minimal nav
  $(window).width() >= 1024 && $(window).width() <= 1280 && $('.page-single').length > 0 ? minimalNav() : $('.main-header').removeClass('main-header--minimal');

  // set height of info texts (office/contact)
  $('.info').length > 0 && $(window).width() > 1280  ? setInfoHeight() : setInfoHeight(false);

  // set project-single images
  $('.flexslider').length > 0 && $(window).width() > 736 ? flexslider() : '';

  // set info overlay functionality
  console.log("init toggleInfoOverlay function resize");

  //toggleInfoOverlay();

  // initialize map
  $('#map').length > 0 ? initMap() : '';
});

// Listen for orientation changes
window.addEventListener("orientationchange", function() {
  // set project-single images
  $('.flexslider').length > 0 && $(window).width() > 736 ? flexslider() : '';

}, false);


/**
* Home slider
*/
function homeSlider() {
  $('.home-slider').flexslider({
    animation: "fade",
    slideshowSpeed: "5000",
    animationSpeed: Modernizr.touch ? 400 : 1200
  });
}

/**
* Scroll down one row (projects selection, homepage)
* @return {[type]} [description]
*/
function arrowScroll() {
  $('.page-nav a').on('click', function(event) {

    if ($(this).attr('href') == "") {
      event.preventDefault();
      if ($(this).parent().hasClass('arrow-down')) {
        var distance = $(document).scrollTop() - $(document).scrollTop()%180 + 180;
      } else if ($(this).parent().hasClass('arrow-up')) {
        var distance = $(document).scrollTop() - $(document).scrollTop()%180 - 180;
      }
    }
    $('html, body').animate({ scrollTop: distance }, 300);
  })
}

function sortTable() {
  var options = {
    valueNames: [ 'name', 'location', 'year', 'type', 'status' ],
    page: 1000
  };

  var projectsAll = new List('projects-all', options);

  var bibliographyOptions = {
    valueNames: [ 'title','author', 'year', 'publication', 'project' ],
    page: 1000
  };

  var bibliography = new List('bibliography', bibliographyOptions);
}

/**
* Lazy load not necessary on mobile, replace all data-src attributes by src
*/
function mobileImages() {
  $('.projects-single__slider')
  .find('img.lazy')
  .each(function() {
    var src = $(this).attr('data-src');
    $(this).attr('src', src).removeAttr('data-src');
  });
}

/**
* Single project slideshow
*/
function flexslider() {
  //console.log("reset flexslider");
  //$('.flexslider').removeData("flexslider");

  $('.slideshow-nav').css('display', 'inline-block')
  $('.slides, .project-text').show();
  $('.projects-single__slider').flexslider({
    animation: "slide",
    slideshow: false,
    controlNav: false,
    customDirectionNav: $(".slideshow-nav a"),
    start: function(slider) {
      var slide_count = slider.count - 1;

      $(slider)
      .find('img.lazy:eq(0)')
      .each(function() {
        var src = $(this).attr('data-src');
        $(this).attr('src', src).removeAttr('data-src');
      });

      // total slide count
      $('.total-slides').text(slider.count);
      // display inital slide caption
      var captionNl = $(slider.slides[slider.animatingTo]).find('img').attr('data-caption-nl');
      var captionEn = $(slider.slides[slider.animatingTo]).find('img').attr('data-caption-en');
      $('.caption-nl').text(captionNl);
      $('.caption-en').text(captionEn);

      // prev/next navigation by clicking on the screen
      $('.arrow-left-area').on('click', function() {
        slider.flexAnimate(slider.getTarget("previous"));
      });
      $('.arrow-right-area').on('click', function() {
        slider.flexAnimate(slider.getTarget("next"));
      });
    },
    before: function(slider) {
      // hide menu if open (minimal layout)
      if($('.minimal-nav').is(":visible")) {
        $('main-header').addClass('main-header--minimal');
        $('.main-nav, .sub-nav').hide();
        $('.minimal-nav-link').show();
        $('.close-area').hide();
      }

      // hide info-overlay if open
      if($('.info-overlay').is(":visible")) {
        toggleInfoOverlayAction();
      }

      // lazy load slides
      var slides     = slider.slides,
      index      = slider.animatingTo,
      $slide     = $(slides[index]),
      $img       = $slide.find('img[data-src]'),
      current    = index,
      nxt_slide  = current + 1,
      prev_slide = current - 1;

      $slide
      .parent()
      .find('img.lazy:eq(' + current + '), img.lazy:eq(' + prev_slide + '), img.lazy:eq(' + nxt_slide + ')')
      .each(function() {
        var src = $(this).attr('data-src');
        $(this).attr('src', src).removeAttr('data-src');
      });

    },
    after: function(slider) {
      // display current slide count
      $('.current-slide').text(slider.currentSlide + 1);
      // display current slide caption
      var captionNl = $(slider.slides[slider.animatingTo]).find('img').attr('data-caption-nl');
      var captionEn = $(slider.slides[slider.animatingTo]).find('img').attr('data-caption-en');
      $('.caption-nl').text(captionNl);
      $('.caption-en').text(captionEn);
    }
  });

  findOutSliderHeight();

  // after everything is loaded, make content area visible.
  $('.projects-single .project-text').show();
}

function findOutSliderHeight() {
  if( window.innerWidth > 767 && window.innerWidth < 1024 && $('.page-single').length > 0 ) {
    // tablet portrait
    //setSliderHeight(260, false, false);
    setSliderHeight(260, true, false);
  } else if(window.innerWidth >= 1024 && window.innerWidth <= 1280 && $('.page-single').length > 0) {
    // tablet landscape
    setSliderHeight(217, true, true);
  } else if(window.innerWidth >= 1280) {
    // desk
    setSliderHeight(260, true, false);
  } else {
    setSliderHeight(200, false);
  }
}

function setSliderHeight(margin, setOverlayPosition, minimal) {

  // set slider height
  var imageHeight = window.innerHeight - margin; //195; // -245;
  $('.projects-single__slider').height(imageHeight);
  $('.projects-single__slider li').height(imageHeight);

  // set sliderheight for minimal layout
  var imageHeight = window.innerHeight - margin; //195; // -245;
  $('.projects-single__slider').height(imageHeight);
  $('.projects-single__slider li').height(imageHeight);

  // set info overlay position
  if(setOverlayPosition != false) {
    var overlayHeight = $('.info-overlay').height();

    if(minimal == true) {
      var topHeight = 80;
    } else {
      var topHeight = 125;
    }
    $('.info-overlay').css({
      'margin-top': (imageHeight + topHeight) - overlayHeight
    });
  } else {
    $('.info-overlay').css({
      'margin-top': '136px'
    });
  }

  // set clickable area for prev/next-navigation
  $('.arrow-left-area, .arrow-right-area').height(imageHeight);
}

function setInfoHeight(set) {

  if(set == false) {

    $('.info').attr('style', function(i, style){
      $('.info').css('margin-top', '');
    });

    $('figure img').height('auto');

    var imageHeight = window.innerHeight - 260; //195; // -245;
    $('figure #map').height(imageHeight);

    return;
  }

  var imageHeight = window.innerHeight - 260; //195; // -245;
  $('.office').show();

  // set info image height
  var infoImageHeight = window.innerHeight - 305;
  $('figure img').height(imageHeight);
  $('figure #map').height(imageHeight);

  // set info position
  var infoHeight = $('.info').height();
  $('.info').css({
    'margin-top': (imageHeight + 125) - infoHeight
  });
  $('.info').show();

}

function toggleInfoOverlay() {
  var text = '';
  $('.overlay-link').on('click touch', function() {
    toggleInfoOverlayAction();
  });
  $('.overlay-link-hide').on('click touch', function() {
    toggleInfoOverlayAction();
  });
}

function toggleInfoOverlayAction() {
  $('.info-overlay').toggle();
  $('.overlay-link span').toggleClass('hidden');
  $('h1 span').toggleClass('hidden');
}

function mobileNav() {
  // toggle main navigation
  var mobileNav = $('.mobile-nav');
  $(mobileNav).hide();
  $('.mobile-nav-link').off('click').on('click', function() {
    $(mobileNav).slideToggle();
  });

  // show/hide button back to top
  $(window).scroll(function() {
    if ( $('body').scrollTop() > 100 ) {
      $('.mobile-backtotop').show();
      $('.logo').hide();
    } else {
      $('.mobile-backtotop').hide();
      $('.logo').show();
    }
  });
  // back to top action
  $('.mobile-backtotop').off().on('click', function() {
    $('html,body').animate({
      scrollTop: 0
    }, 700);
    return false;
  });
}

function minimalNav() {
  $('.main-header').addClass('main-header--minimal');

  $('.minimal-nav-link').click(function(e) {
    e.preventDefault();
    $('.main-header').addClass('main-header--open');
    // show menu
    $('.main-nav, .sub-nav').show();

    // hide button
    $(this).hide();
    $('.close-area').show();
  });

  $('.close-area').click(function(e) {
    e.preventDefault();
    $('.main-header').addClass('main-header--closed');
    $('.main-nav, .sub-nav').hide();
    $('.minimal-nav-link').show();
    $('.close-area').hide();
  });
}

function initMap() {
  //https://snazzymaps.com/editor
  var styleArray = [
    {
      "featureType": "all",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "saturation": "-100"
        },
        {
          "lightness": "-89"
        },
        {
          "color": "#bbbbbb"
        }
      ]
    },
    {
      "featureType": "all",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "off"
        },
        {
          "color": "#bbbbbb"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "simplified"
        },
        {
          "color": "#892525"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#dddddd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#bbbbbb"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#bbbbbb"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "gamma": "0.00"
        }
      ]
    }
  ];
  var myLatLng = {lat: 52.2253751, lng: 5.1780142};

  // Create a map object and specify the DOM element for display.
  var map = new google.maps.Map(document.getElementById('map'), {
    center: myLatLng,
    scrollwheel: false,
    zoomControl: true,
    mapTypeControl: false,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL
    },
    panControl: false,
    streetViewControl: false,
    // Apply the map style array to the map.
    styles: styleArray,
    zoom: 16
  });
  var image = {
    url: '../img/marker2.png',
    scaledSize: new google.maps.Size(33, 53)
  }
  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Koen van Velsen architecten',
    icon: image
  });

  console.log("init map");

  // calculate width of map
  var mapHeight = $('#map').height();
  var mapWidth = mapHeight * 0.686;

  // set width of the map if > 1280
  $(window).width() >= 1280 ? $("#map").width(mapWidth) : "";

  // set height of the map if < 1280
  if($(window).width() >= 768 && $(window).width() < 1280) {
    $("#map").width('');
    var mapWidth = $('#map').width();
    var mapHeight = mapWidth * 1.44;
    $("#map").height(mapHeight);
  }
}
